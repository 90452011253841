














































import Vue, { PropOptions } from "vue";

import { MainAlertButton, Translation } from "#root/lib/interfaces";
import { t } from "#root/lib/utils";

export default Vue.extend({
  name: "MainAlert",
  computed: {
    shownGet: {
      get() {
        return this.shown;
      },
      set(value: boolean) {
        this.$emit("setShown", value);
      },
    },
  },
  props: {
    shown: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    title: {
      type: Object as () => Translation,
    },
    message: {
      type: Object as () => Translation,
    },
    buttons: {
      type: Array,
      default: () => [],
    } as PropOptions<MainAlertButton[]>,
    persistent: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    t: t,
    onClick(): void {
      this.$emit("click");
    },
  },
});
